const IconSvg = () => {
    return (
        <svg width="768" height="786" viewBox="0 0 768 786" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.2289 327.468C90.5368 267.112 126.7 211.724 175.444 172.437C197.389 154.748 250.785 127.327 277.875 119.83C389.338 88.9918 507.113 122.509 583.15 206.708C609.161 235.514 637.205 286.413 647.452 323.418C667.679 396.47 660.862 471.855 627.988 538.637C603.495 588.398 570.765 624.481 522.064 655.412C446.322 703.515 373.48 715.526 287.748 694.047C248.834 684.294 232.513 674.298 220.103 652.607C212.162 638.726 210.533 631.135 207.317 592.98C200.196 508.535 192.467 494.831 126.301 449.31C90.4612 424.654 86.4724 421.061 77.4003 405.244C64.498 382.743 63.8138 366.002 74.2289 327.468Z" fill="#E6E1D6" />
        </svg>
    );
}


export const ProductBackgroundShape = ({className}) => <div className={className}><IconSvg /></div>;
